import { Calendar, Cookies, Restaurant, SeatingData } from '@maverick/entity';
import { Button, Icon } from '@maverick/ui';
import { filterRestaurantHours, GaEvent, getDateWithOffset, handleRestaurantInfo } from '@maverick/utils';
import { FC, useEffect, useState } from 'react';
import { useTheme } from 'styled-components';
import { IconClock } from '@maverick/icons/dist/IconClock';
import * as Styled from './LocationPopUp.styled';
import Link from 'next/link';
import { useCookies } from '@maverick/hooks';
import { LocationManager } from '@maverick/cms';
import { LocationPopUpReduxProps } from '.';

interface LocationPopUpProps extends LocationPopUpReduxProps {
	yext: string | null;
	seatingDetails: string | null;
	seatingData: SeatingData | null;
	handleModal: () => void;
}

export const LocationPopUp: FC<LocationPopUpProps> = ({
	yext,
	seatingDetails,
	seatingData,
	handleModal,
	waitTime,
	restaurant,
	setWaitTime,
}) => {
	const { cmsComponents } = useTheme();
	const [favoriteStore] = useCookies(Cookies.FavoriteStore);
	const [status, setStatus] = useState<boolean | null>(null);
	const [restaurantInfo, setRestaurantInfo] = useState({
		restaurantName: null as string | null,
		restaurantAddress: null as string | null,
		restaurantHours: null as string | null | undefined,
	});

	useEffect(() => {
		handleRestaurantHours();
		handleLocation();
	}, [favoriteStore]);

	const handleLocation = async () => {
		await Promise.all([handleRestaurantOpen(), handleTableWait()]);
	};

	const handleRestaurantOpen = async () => {
		if (!favoriteStore || !restaurant) {
			return;
		}
		const result = await LocationManager.GetLocationCalendars(restaurant.id);
		if (!result) {
			return;
		}

		const now = getDateWithOffset(restaurant.utcoffset);
		const isOpen = result.businessCalendar?.ranges.some((r) => now >= r.start && now <= r.end) ?? null;
		const calendar = () => {
			const calendarArray = [result.businessCalendar, result.curbsideCalendar, result.deliveryCalendar];
			const resultArray: Calendar[] = [];
			calendarArray.forEach((c) => {
				if (!!c) resultArray.push(c);
			});
			return resultArray.length > 0 ? resultArray : undefined;
		};

		const newRestaurant = { ...restaurant, calendars: calendar() };
		handleRestaurantHours(newRestaurant);
		setStatus(isOpen);
	};

	const handleRestaurantHours = (newRestaurant?: Restaurant) => {
		const selectedRestaurant = newRestaurant || restaurant;
		if (!selectedRestaurant) {
			return;
		}
		const { restaurantName, restaurantAddress } = handleRestaurantInfo(selectedRestaurant);
		const restaurantHours =
			selectedRestaurant.calendars && selectedRestaurant.calendars.length > 0
				? filterRestaurantHours(selectedRestaurant.calendars)
				: null;
		setRestaurantInfo({
			restaurantName,
			restaurantAddress,
			restaurantHours,
		});
	};

	const handleTableWait = async () => {
		if (!favoriteStore || !restaurant) {
			return;
		}

		const result = await LocationManager.GetTableWait(favoriteStore);
		if (!result) {
			return;
		}
		const waitTime = { time: result.waitTime };
		setWaitTime(waitTime);
	};

	const renderRestaurantHours = () => {
		return (
			<Styled.RestaurantHoursContainer>
				<Styled.HoursIconContainer>
					<Icon icon={IconClock} customSize />
				</Styled.HoursIconContainer>
				<Styled.RestaurantHours
					data-testid='location-restaurant-hours'
					aria-label={
						restaurantInfo.restaurantHours
							? `Restaurant hours: ${restaurantInfo.restaurantHours}`
							: undefined
					}
					aria-hidden={restaurantInfo.restaurantHours === null}
					tabIndex={0}
				>
					Restaurant hours: {restaurantInfo.restaurantHours}
				</Styled.RestaurantHours>
			</Styled.RestaurantHoursContainer>
		);
	};

	const renderSeatingDetails = () => {
		if (seatingDetails && seatingData) {
			return (
				<Styled.SeatingContainer>
					<Styled.SeatingDetails data-testid='location-seating-details' aria-label={`${seatingDetails}`}>
						<Link
							href={`/reservations/details?unit=${seatingData.LocationId}&partyid=${seatingData.PartyKey}`}
							onClick={() => {
								GaEvent.TopNavigation('details');
								GaEvent.InternalLinkClick('/reservations/details');
							}}
						>
							{seatingDetails}
						</Link>
					</Styled.SeatingDetails>
				</Styled.SeatingContainer>
			);
		} else if (status !== null) {
			return (
				<Styled.SeatingContainer>
					<Styled.SeatingStatus
						data-testid='location-seating-status'
						status={status}
						aria-label={`${status}`}
						tabIndex={0}
					>
						{status ? 'Open' : 'Closed'}
					</Styled.SeatingStatus>
					<Styled.SeatingDetails data-testid='location-wait-time'>
						&nbsp;/&nbsp;
						<Link
							href='/reservations'
							onClick={() => {
								GaEvent.TopNavigation('wait time');
								GaEvent.InternalLinkClick('/reservations');
								handleModal();
							}}
						>
							{status && waitTime !== null && waitTime.time.toString() !== '0'
								? `${waitTime.time} min. wait`
								: 'No wait time'}
						</Link>
					</Styled.SeatingDetails>
				</Styled.SeatingContainer>
			);
		}
		return null;
	};

	const renderButtons = () => {
		return (
			<Styled.ButtonsContainer>
				<Button
					text='Change location'
					padding='8px 0'
					variant='senary'
					data-testid='change-location'
					id='change-location'
					onClick={() => (window.location.href = 'https://locations.outback.com/search')}
					onKeyDown={(ev) => {
						if (ev.key == 'Enter') {
							window.location.href = 'https://locations.outback.com/search';
						}
					}}
				/>
				<Button
					text='View more info'
					padding='8px 0'
					variant='septenary'
					data-testid='view-more'
					id='view-more'
					onClick={() => (window.location.href = `https://locations.outback.com/${yext || 'search'}`)}
					onKeyDown={(ev) => {
						if (ev.key == 'Enter') {
							window.location.href = `https://locations.outback.com/${yext || 'search'}`;
						}
					}}
				/>
			</Styled.ButtonsContainer>
		);
	};

	return (
		<Styled.Container data-testid='location-pop-up'>
			<Styled.Header data-testid='location-brand-name'>Your {cmsComponents.shortName}</Styled.Header>
			<Styled.RestaurantNameContainer>
				<Styled.NameIconContainer>
					<Icon icon={cmsComponents.icons.iconLocation} customSize />
				</Styled.NameIconContainer>
				<Styled.RestaurantName
					data-testid='location-restaurant-name'
					aria-label={
						restaurantInfo.restaurantName ? `Restaurant name: ${restaurantInfo.restaurantName}` : undefined
					}
					tabIndex={0}
				>
					{restaurantInfo.restaurantName}
				</Styled.RestaurantName>
			</Styled.RestaurantNameContainer>
			<Styled.RestaurantAddress
				data-testid='location-restaurant-address'
				aria-label={
					restaurantInfo.restaurantAddress
						? `Restaurant address: ${restaurantInfo.restaurantAddress}`
						: undefined
				}
				tabIndex={0}
			>
				{restaurantInfo.restaurantAddress}
			</Styled.RestaurantAddress>
			{renderRestaurantHours()}
			{renderSeatingDetails()}
			{renderButtons()}
		</Styled.Container>
	);
};

import { LocationPopUp } from './LocationPopUp';
import { connect, ConnectedProps } from 'react-redux';
import { LocationOperations, RootState } from '@maverick/store';

const mapStateToProps = (state: RootState) => ({
	seatingData: state.seating.seatingData,
	waitTime: state.location.waitTime,
	restaurant: state.location.restaurant,
});

const mapDispatchToProps = {
	setWaitTime: LocationOperations.setWaitTime,
};

const connector = connect(mapStateToProps, mapDispatchToProps);
export type LocationPopUpReduxProps = ConnectedProps<typeof connector>;
const LocationPopUpConnected = connector(LocationPopUp);
export { LocationPopUpConnected as LocationPopUp };
